var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.building,
          _vm.getHelperObject('building_type', 'building.building_type_id'),
          _vm.getHelperObject('property_unit', 'building.property_unit_id'), // TODO: use helpers ?
          _vm.helpers.currenOwner,
          _vm.getHelperObject('imputation'),
          _vm.getHelperObject('module'),
          _vm.helpers.sharesValue
        ],"config":{
          url: 'admin/building_shares',
          route: '/admin/property/shares',
          name: 'share',
          lname: 'building_shares',
          params: { _lists: 'modules,imputations,building_types,property_units,property_levels' }
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }