<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.building,
            getHelperObject('building_type', 'building.building_type_id'),
            getHelperObject('property_unit', 'building.property_unit_id'), // TODO: use helpers ?
            helpers.currenOwner,
            getHelperObject('imputation'),
            getHelperObject('module'),
            helpers.sharesValue
          ]"
          :config="{
            url: 'admin/building_shares',
            route: '/admin/property/shares',
            name: 'share',
            lname: 'building_shares',
            params: { _lists: 'modules,imputations,building_types,property_units,property_levels' }
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'shares',
  mixins: [crudMixin]
}
</script>
